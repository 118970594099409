class VirtualListElement<V> extends HTMLElement implements Set<V> {
  #items: Set<V> = new Set()

  // Heights is used to track the rendered height
  // of a list element. This is useful because the
  // height may vary from item to item, and so each
  // height must be tracked.
  #heights: Map<V, number> = new Map()
  #assumedHeight = Infinity

  #rangeCache = new Map<string, [number, number]>()
  #renderCache = new Map<V, HTMLLIElement>()

  #animationFrameId = 0

  get size(): number {
    return this.#items.size
  }

  get range(): [number, number] {
    const visibleHeight = this.getBoundingClientRect().height
    const {scrollTop} = this
    const key = `${scrollTop}-${visibleHeight}`
    if (this.#rangeCache.has(key)) return this.#rangeCache.get(key)!
    let rowStart = 0
    let rowEnd = 0
    let startHeight = 0
    let endHeight = 0
    const heights = this.#heights
    for (const item of this.#items) {
      const height = heights.get(item) || this.#assumedHeight
      if (startHeight + height < scrollTop) {
        startHeight += height
        rowStart += 1
        rowEnd += 1
      } else if (endHeight - height < visibleHeight) {
        endHeight += height
        rowEnd += 1
      } else if (endHeight >= visibleHeight) {
        break
      }
    }
    return [rowStart, rowEnd]
  }

  connectedCallback(): void {
    // eslint-disable-next-line github/prefer-observers
    this.addEventListener('scroll', () => this.update())
    this.updateSync = this.updateSync.bind(this)
  }

  update(): void {
    if (this.#animationFrameId) cancelAnimationFrame(this.#animationFrameId)
    this.#animationFrameId = requestAnimationFrame(this.updateSync)
  }

  private renderItem(item: V): HTMLLIElement {
    const detail = {item, fragment: document.createDocumentFragment()}
    this.dispatchEvent(new CustomEvent('virtual-list-render-item', {detail}))
    if (detail.fragment.children.length > 1 || !(detail.fragment.children[0] instanceof HTMLLIElement)) {
      throw new Error('Rendered element must be an HTMLLIElement')
    }
    return detail.fragment.children[0]
  }

  private updateSync(): void {
    const list = this.querySelector<HTMLElement>('ul, ol')
    if (!list) return
    const [rowStart, rowEnd] = this.range
    if (rowEnd <= rowStart) return
    const cancelled = !this.dispatchEvent(new CustomEvent('virtual-list-update', {cancelable: true}))
    if (cancelled) return
    const itemsRows = new Map<V, HTMLLIElement>()
    const renderCache = this.#renderCache
    let i = -1
    let renderEnd = true
    let startHeight = 0
    for (const item of this.#items) {
      i += 1
      if (i < rowStart) {
        startHeight += this.#heights.get(item) || this.#assumedHeight
        continue
      }
      if (i > rowEnd) {
        renderEnd = false
        break
      }
      let row = null
      if (renderCache.has(item)) {
        row = renderCache.get(item)!
      } else {
        row = this.renderItem(item)
        renderCache.set(item, row)
      }
      itemsRows.set(item, row)
    }
    list.replaceChildren(...itemsRows.values())
    list.style.paddingTop = `${startHeight}px`
    list.style.height = `${this.size * this.#assumedHeight}px`

    // The itemsRows list must be iterated after all rows have been rendered to get accurate heights
    let renderedPastBottom = false
    const scrollBottom = this.getBoundingClientRect().bottom
    for (const [item, row] of itemsRows) {
      const {height, bottom} = row.getBoundingClientRect()
      renderedPastBottom = renderedPastBottom || bottom >= scrollBottom
      this.#heights.set(item, height)
    }

    const moreItemsToRender = !renderEnd && this.size > itemsRows.size
    if (moreItemsToRender && !renderedPastBottom) {
      this.#rangeCache.delete(`${this.scrollTop}-${this.getBoundingClientRect().height}`)
      return this.update()
    }
    this.dispatchEvent(new CustomEvent('virtual-list-updated'))
  }

  has(value: V): boolean {
    return this.#items.has(value)
  }

  add(value: V): this {
    this.#items.add(value)
    // If this is the first item added we need to render it to get an estimate for the row height of an entity.
    if (!Number.isFinite(this.#assumedHeight)) {
      const list = this.querySelector('ul, ol')
      if (list) {
        list.append(this.renderItem(value))
        this.#assumedHeight = list.children[0].getBoundingClientRect().height
        this.#heights.set(value, this.#assumedHeight)
        list.replaceChildren()
      }
    }
    this.update()
    return this
  }

  delete(value: V): boolean {
    const ret = this.#items.delete(value)
    this.#heights.delete(value)
    this.update()
    return ret
  }

  clear(): void {
    this.#items.clear()
    this.#heights.clear()
    this.update()
  }

  forEach(callbackfn: (value: V, value2: V, set: Set<V>) => void, thisArg?: unknown): void {
    for (const item of this) callbackfn.call(thisArg, item, item, this)
  }

  entries(): IterableIterator<[V, V]> {
    return this.#items.entries()
  }

  values(): IterableIterator<V> {
    return this.#items.values()
  }

  keys(): IterableIterator<V> {
    return this.#items.keys()
  }

  [Symbol.iterator](): IterableIterator<V> {
    return this.#items[Symbol.iterator]()
  }

  sort(compareFn: (a: V, b: V) => number): this {
    this.#items = new Set(Array.from(this).sort(compareFn))
    this.update()
    return this
  }
}

declare global {
  interface Window {
    VirtualListElement: typeof VirtualListElement
  }
  // @ts-fixme HTMLElement doesn't expose `Symbol.toStringTag`
  interface HTMLElement {
    [Symbol.toStringTag]: 'HTMLElement'
  }
}

export default VirtualListElement

if (!window.customElements.get('virtual-list')) {
  window.VirtualListElement = VirtualListElement
  window.customElements.define('virtual-list', VirtualListElement)
}
